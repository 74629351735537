import React from 'react';
import { Expo, TweenMax } from "gsap/TweenMax"
import PropTypes from "prop-types"
import { MainMenu } from "../../core/MainMenu"

export class ImageShowAnimated extends React.Component {

  componentDidMount() {
    const bgImg = new Image();
    bgImg.onload = () => this.aniMask();
    bgImg.src = this.props.image;
    // bgImg.src = '../static/home/training-sky.jpg';

  }

  aniMask() {
    const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    const size = w > h ? w : h;
    TweenMax.to(".clip-circle", 1.00, {
      delay: 0,
      webkitClipPath: `circle(${size/2 + this.props.widthAdditional} at 50% 50%)`,
      clipPath: `circle(${size/2 + this.props.widthAdditional} at 50% 50%)`,
      ease: Expo.easeInOut
    });
  }


  render() {
    const styles = {
      // background: `url("${getStaticPath()}static/home/training-sky.jpg") no-repeat center center`,
      background: `url("${this.props.image}") no-repeat center center`,
      backgroundSize: 'cover',
      ...this.props.style
    }

    return (
      <div
        className={this.props.className}
        style={styles}
      >
        {this.props.children}
      </div>
    )
  }
}




ImageShowAnimated.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.object,
  widthAdditional: PropTypes.number,
  children: PropTypes.node,
}

ImageShowAnimated.defaultProps = {
  widthAdditional: 50
}
