import React from 'react';
import PropTypes from 'prop-types';
import { getStaticPath } from "../../../shared/config"
import { graphql, StaticQuery } from "gatsby"
import cn from "classnames"
import css from "./Home2.module.css"
import '../../../shared/css/ui-elements.css';

export const squareImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      fluid(maxWidth: 200) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export default class Home2 extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
        query {
          ts: file(relativePath: { eq: "loghi/ts.png" }) { ...squareImage },
          js: file(relativePath: { eq: "loghi/js.png" }) { ...squareImage },
          angular2: file(relativePath: { eq: "loghi/angular2.png" }) { ...squareImage },
          react: file(relativePath: { eq: "loghi/react.png" }) { ...squareImage },
          ngrx: file(relativePath: { eq: "loghi/ngrx.png" }) { ...squareImage },
          rxjs: file(relativePath: { eq: "loghi/rxjs.png" }) { ...squareImage },
          redux: file(relativePath: { eq: "loghi/redux.png" }) { ...squareImage },
          gde_angular_badge: file(relativePath: { eq: "profile/2019-GDE-Angular-Badge.png" }) { ...gdeLogo },
          gde_web_badge: file(relativePath: { eq: "profile/2019-GDE-Web-Technologies-Badge.png" }) { ...gdeLogo },
        }
      `}
        render={data => <Home2Content data={data} />}
      />
    )
  }
}

export  class Home2Content extends React.Component {
  render() {


    return <div>
      <div className="page_wrapper"
           style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
          <div className={cn('page_centered_content')} >
            <div className={css.container}>

              <img alt="logo" className={cn(css.gde_logo)}
                   src={this.props.data.gde_angular_badge.childImageSharp.fluid.src}
                   style={{margin: '0 1rem'}} />
              <img alt="logo" className={cn(css.gde_logo)}
                   src={this.props.data.gde_web_badge.childImageSharp.fluid.src}
                   />
              <br/>

              <div style={{padding:10}}>
                {/*<Img fluid={this.props.data.angular2.childImageSharp.fluid} alt="" />*/}
                <img alt="logo"
                     className={css.social_icon}
                     src={this.props.data.angular2.childImageSharp.fluid.src} width={55}/>
                <img alt="logo"
                     className={css.social_icon}
                     src={this.props.data.react.childImageSharp.fluid.src} width={55}/>
                <img alt="logo"
                     className={css.social_icon}
                     src={this.props.data.js.childImageSharp.fluid.src} width={55}/>

                <img alt="logo"
                     className={css.social_icon}
                     src={this.props.data.ngrx.childImageSharp.fluid.src} width={55}/>

                <img alt="logo"
                     className={css.social_icon}
                     src={this.props.data.redux.childImageSharp.fluid.src} width={55}/>

                <img alt="logo"
                     className={css.social_icon}
                     src={this.props.data.ts.childImageSharp.fluid.src} width={55}/>
              </div>

              <br/>
              <a href="./about">
                <div className={cn('button_outline button_outline_big noSelect', css.button_for_low_height)}
                     style={{ marginBottom: 5, marginRight: 5}}>
                  <i className="fa fa-user"></i> ABOUT ME
                </div>
              </a>

              <a href="./training">
                <div className={cn('button_outline button_outline_big border-angular noSelect', css.button_for_low_height)}
                     style={{ marginBottom: 5, marginRight: 5}}>
                  <i className="fa fa-graduation-cap"></i> TRAINING
                </div>
              </a>
            </div>

            <div style={{padding:10}}>
              {/*<Img fluid={this.props.data.angular2.childImageSharp.fluid} alt="" />*/}

            </div>
            </div>



      </div>

    </div>
  }
}

Home2.propTypes = {
  onGotoPage: PropTypes.func,
}
