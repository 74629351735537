import React from "react"
import { MAIN_MENU_OPENED_ONCE, saveToLocalStorage } from "../shared/utils/local-storage"

import '../shared/css/styles.css';
import HomePage from "./home"

class IndexPage extends React.Component {
  componentDidMount() {
    saveToLocalStorage(MAIN_MENU_OPENED_ONCE, false)
  }

  render() {
    return <HomePage {...this.props}/>;
  }
}

export default IndexPage;
