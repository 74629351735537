import React from 'react';
import { PageScroller } from "../../shared/components/PageScroller"
import Home1 from "./pages/Home1"
import Home2 from "./pages/Home2"
import Layout from "../../layouts/Layout"
import Home3 from "./pages/Home3"
import css from './HomePage.module.css';
import '../../shared/css/ui-elements.css';
import cn from 'classnames';
// import BG1 from '../../../_public/static/home/home2.jpg'
import BG1 from '../../images/_static/home2.jpg';
import BG2 from '../../images/_static/profilo1.jpg';
import BG3 from '../../images/_static/home1.jpg';
import { TweenMax, Power4 } from "gsap/TweenMax"
import { getFromLocalStorage, MAIN_MENU_OPENED_ONCE } from "../../shared/utils/local-storage"
import { ScrollToPlugin } from "gsap/all"
import { getStaticPath } from "../../shared/config"
import { ImageShowAnimated } from "../../shared/components/ImageShowAnimated"

export default class HomePage extends React.Component {
  state = { index: 0, rightButtonLabel: 'GO TO <BR />SERVICES' };
  isAnimatingArrow = false;
  componentDidMount() {
    const a = ScrollToPlugin;
    this.moveArrowToMenu();
  }

  moveArrowToMenu = () => {
    if (this.isAnimatingArrow || TweenMax.isTweening('.page_carousel_right_animated')) { return };

    if (getFromLocalStorage(MAIN_MENU_OPENED_ONCE) === 'true') {
      return;
    }

    if (!TweenMax.isTweening('.page_carousel_right_animated')) {
      this.isAnimatingArrow = true;

      const ani = TweenMax.to('.page_carousel_right_animated', 2, {
        delay: 2.5, left: '5rem', top: '4rem', rotation: 225, transformOrigin:"left 50%", margin: 0,
        ease: Power4.easeInOut,
        onComplete: () => {
          ani.reverse();
          setTimeout(() => {
            TweenMax.set('.page_carousel_right_animated', { clearProps: 'all' });
            this.isAnimatingArrow = false;
          }, 2100)
        }})
    }




  }

  gotoPage = (index) => {
    if (index === 0){
      this.moveArrowToMenu();
    }

    TweenMax.to('.page_wrapper_side_text', 1, {scrollTo: 0});


    let rightButtonLabel;
    switch (index) {
      case 0: rightButtonLabel = 'GOTO 1';
      case 2: rightButtonLabel = 'GOTO 2';
      default: rightButtonLabel = '';
    }
    this.setState({ index, rightButtonLabel });
  }

  render() {
    const styles = { 
      /*bg0: {
        /!*background: `url("../static/home/home2.jpg") no-repeat center center`,*!/
        background: `url("${BG2}") no-repeat center center`,
        backgroundSize: 'cover'
      },*/
      bg2: {
        /*background: `url("../static/home/home2.jpg") no-repeat center center`,*/
        background: `url("${BG2}") no-repeat center center`,
        backgroundSize: 'cover'
      },
      bg3: {
        /*background: `url("../static/home/home2.jpg") no-repeat center center`,*/
        background: `url("${BG3}") no-repeat center center`,
        backgroundSize: 'cover'
      },
    }

    return( <Layout {...this.props}>
        <PageScroller
          index={this.state.index}
          onGotoPage={index => this.gotoPage(index)}
          rightButtonLabel={this.state.rightButtonLabel}
        >
            <ImageShowAnimated
              className={cn(css.page, 'page1', 'clip-circle')}
              image={BG1}
              widthAdditional={170}
            >
              <Home1 onGotoPage={(index) => this.gotoPage(index)} />
            </ImageShowAnimated>

          <div className={css.page}  style={styles.bg2}>
            <Home3 onGotoPage={(index) => this.gotoPage(index)} />
          </div>
          <div className={css.page} style={styles.bg3}>
            <Home2 onGotoPage={(index) => this.gotoPage(index)} />
          </div>
        </PageScroller>
      </Layout>)
  }
}

