import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Swipe from 'react-easy-swipe';
import { TweenMax, Power1 } from "gsap/TweenMax"

import css from './page-scroller.module.css';

export class PageScroller extends React.PureComponent {

  state = { showBlockOverlay: false};

  componentDidMount() {
    const ani = TweenMax.to('.fa-arrow-right', 1, {
      paddingRight: '3rem',
      repeat: -1,
      delay: 0,
      ease: Power1.easeInOut
    })
    // ani.repeat()
    ani.yoyo(true)
    this.moveIndicators(0);
    // this.transitionEndManager();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.index !== this.props.index) {
      this.moveIndicators(this.props.index);
    }
  }

/*
  transitionEndManager() {
    const transition = document.querySelector('.' + css.carousel);

    transition.addEventListener('transitionstart', () => {
      console.log('Transition start');
      alert()
      this.setState({ showBlockOverlay: true })
    });

    transition.addEventListener('transitionend', () => {
      console.log('Transition ended');
      this.setState({ showBlockOverlay: false })
    });
  }*/

  moveIndicators(index) {

    TweenMax.to(`.${css.carousel_indicators_active}`, 0.5, {
      left: `calc(${index * 33}% - 4px)`,
    })
  }

  gotoRight  = () => {
    let nextIndex;
    if (this.props.index < this.props.children.length - 1) {
      nextIndex = this.props.index + 1;
      this.props.onGotoPage(nextIndex);

    } /*else {
      nextIndex = 0;
    }*/
  }

  gotoLeft = () => {
    let nextIndex;
    if (this.props.index > 0) {
      nextIndex = this.props.index - 1;
      console.log(this.props.index, nextIndex)
      this.props.onGotoPage(nextIndex);

    }/* else {
      nextIndex = this.props.children.length - 1;
    }*/
      }

  render() {
    const styles = {
      wrapper: {
        transform: `translateX(-${100 * this.props.index}vw)`
      }
    }
    return <div>
        <div
          className={cn(css.carousel_page_arrow_left, 'noSelect', {[css.hide]:  this.props.index === 0 })}
          onClick={() => this.gotoLeft()}
        >
          <div>
            <i className="fa fa-angle-left"></i>
          </div>
        </div>

        <div
          className={cn('page_carousel_right_animated', 'noSelect', css.carousel_page_arrow_right, {[css.hide]:  this.props.index >= this.props.children.length - 1 })}
          onClick={() => this.gotoRight()}
        >
            <i className="fa fa-angle-right"></i>
        </div>

        <div className={css.carousel_indicators }>
          <div style={{position: 'relative', margin: 'auto', width: 100, height: 50}}>
            <div
            onClick={() => this.props.onGotoPage(0)}
              className={cn(css.carousel_indicators_circle, css.carousel_indicators_circle_1)}></div>
            <div
            onClick={() => this.props.onGotoPage(1)}
              className={cn(css.carousel_indicators_circle, css.carousel_indicators_circle_2)}></div>
            <div
            onClick={() => this.props.onGotoPage(2)}
              className={cn(css.carousel_indicators_circle, css.carousel_indicators_circle_3)}></div>
            <div className={cn(css.carousel_indicators_circle, css.carousel_indicators_active)}></div>
          </div>
        </div>



      <Swipe
        onSwipeLeft={this.gotoRight}
        onSwipeRight={this.gotoLeft}
        tolerance = { 100 }
      >

        <div className={css.carousel} style={styles.wrapper}>
          {
            this.props.children.map((child, index) => {
              return React.createElement('div', {
                ...styles,
                key: index,
                className: css.slide,
                /*className: cn(css.slide, ...child.props.className)*/
              }, child)
            })
          }


        </div>
      </Swipe>

    </div>

  }
}

PageScroller.propTypes = {
  index: PropTypes.number,
  children: PropTypes.arrayOf(PropTypes.node),
  onGotoPage: PropTypes.func,
}
