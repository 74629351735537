import React from "react"
import HomePage from "./home/HomePage"
import '../../node_modules/font-awesome/css/font-awesome.min.css';
import '../shared/css/typography.css';
import '../shared/css/ui-elements.css';
import '../shared/css/styles.css';
import '../shared/css/page.css';
import SEO from "../core/seo"

const SecondPage = props => ( <div>
  <SEO title="Homepage"/>
  <HomePage {...props}/>
</div>)

export default SecondPage
