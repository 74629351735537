import React from 'react';
import PropTypes from 'prop-types';
import css from './Home3.module.css';
import cn from 'classnames';
import { graphql, StaticQuery } from "gatsby"


export default class Home3 extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            photo_profile: file(relativePath: { eq: "profile/fb_square_angular.png" }) { ...photo },
            gde_web_badge: file(relativePath: { eq: "profile/2019-GDE-Web-Technologies-Badge.png" }) { ...gdeLogo },
            gde_angular_badge: file(relativePath: { eq: "profile/2019-GDE-Angular-Badge.png" }) { ...gdeLogo },
            gde_angular: file(relativePath: { eq: "profile/angular-gde-logo@2x.png" }) { ...gdeLogo },
            codemotion_roma2019: file(relativePath: { eq: "home/codemotion-roma2019.png" }) { ...photo },
          }
        `}
        render={data => <Home3Content {...this.props}  data={data} /> }
      />
    )
  }
}

export class Home3Content extends React.Component {
  render() {
    const styles = {
      // background: `url("${getStaticPath()}static/home/profilo2.jpg") no-repeat center center`,
      background: `url("${(this.props.data.codemotion_roma2019.childImageSharp.fluid.src)}") no-repeat center center`,
      backgroundSize: 'cover'
    }

    return (
      <div>
        <div className="page_wrapper"
             style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
          <div className={cn('page_centered_content')} >
            <div className={css.container} >


              <div className="title_xxxl text-shadow-xxl">What I do<span className="color-js">?</span></div>

              <div className="title_text_m text-normal">
                   I help teams & developers:
              </div>
              <br/>
              <div className="title_text_sm text-normal"
                   style={{margin: '0 2rem'}}>
                <span className="text-bold">training</span>,
                <span className="text-bold"> mentoring</span>,<br />
                <span className="text-bold">code-review</span> & <span className="text-bold">development</span>

              </div>


              <br/>
              <a href="./services">
                <div className="button_outline button_outline_big noSelect border-js">
                  <i className="fa fa-code"></i> SERVICES
                </div>
              </a>
            </div>

            <div style={{padding:10}}>
              {/*<Img fluid={this.props.data.angular2.childImageSharp.fluid} alt="" />*/}

            </div>
          </div>



        </div>

      </div>
    )

  }
}

Home3.propTypes = {
  onGotoPage: PropTypes.func,
}

